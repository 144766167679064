import { readableDate } from '@punnet/pure-utility-kit'
import type { Component } from 'solid-js'
import type { Claim } from './ClaimsList'

export const ClaimRow: Component<{ claim: Claim }> = (props) => {
    const claim = props.claim

    return (
        <tr>
            <td>{claim.reference}</td>
            <td>{claim.member.firstname}</td>
            <td>{claim.member.lastname}</td>
            <td>{claim.policyNumber}</td>
            <td>{claim.company.name}</td>
            <td>{readableDate(claim.dateSubmitted, 'dd MMM yyyy')}</td>
            <td>{claim.benefitType}</td>
            <td>{claim.status}</td>
            <td></td>
        </tr>
    )
}