import { type JSXElement } from 'solid-js'
import type { RestrictedRouteDefinition } from './RestrictedRouteDefinition'


export class RestrictedRoutes {

    // these store all the routes and the flattened routes for route processing
    private routes: RestrictedRouteDefinition[]
    private flattenedRoutes: RestrictedRouteDefinition[]

    // these store the routes the user has access to - used by solid router
    private accessibleRoutes: RestrictedRouteDefinition[]

    constructor(routes: RestrictedRouteDefinition[]) {
        this.routes = routes

        // flatten the routes so we can match against the full path of both root routes and child routes
        this.flattenedRoutes = this.#flattenRoutes(routes)
    }

    getRoutes() {
        const filteredRoutes = this.routes.filter((route: RestrictedRouteDefinition) => route.canAccess())
        this.accessibleRoutes = filteredRoutes
        console.log(filteredRoutes.map(route => route.path))
        return filteredRoutes
    }

    isRouteAccessible(path: string) {
        return this.accessibleRoutes.some(route => this.#matchPath(path, route))
    }

    getRouteNavigationPrimary(path: string): JSXElement | undefined {
        const route = this.#findMatchingRoute(path)
        return route?.navigation?.primary
    }
    
    getRouteNavigationSecondary(path: string): JSXElement | undefined {
        const route = this.#findMatchingRoute(path)
        return route?.navigation?.secondary
    }

    #findMatchingRoute(path: string) {
        let route = this.flattenedRoutes.find(route => this.#matchExactPath(path, route)) //prefer exact match
        route = route ?? this.flattenedRoutes.find(route => this.#matchRegex(path, route)) //then regex match
        return route
    }
    
    #flattenRoutes(routes: RestrictedRouteDefinition[]) {
        return routes.flatMap(route => {
            const childrenWithFullPath = (route.children ?? []).map((child: RestrictedRouteDefinition) => ({
                ...child,
                path: `${route.path}${child.path}`
            }))
            return [route, ...childrenWithFullPath]
        })
    }

    #matchExactPath(path: string, route: RestrictedRouteDefinition) {
        return route.path === path
    }

    #matchRegex(path: string, route: RestrictedRouteDefinition) {
        if (route.regex) {
            const regex = new RegExp(route.regex)
            return regex.test(path)
        }
        return false
    }

    #matchPath(path: string, route: RestrictedRouteDefinition) {
        return this.#matchExactPath(path, route) || this.#matchRegex(path, route)
    }
}
