import { PageRouter } from '../../../../../components/PageRouter/PageRouter'
import { PurchaseQuote } from '../PurchaseQuote'
import { SubscriptionQuoteLoader } from '../SubscriptionQuoteLoader'
import { Welcome } from '../Welcome'
import { Authorise } from './Authorise'
import { InviteFinanceContact } from './InviteFinanceContact'

const BASE_PATH = '/quotes/dd/:quoteId'

const routes = [
    {
        component: InviteFinanceContact,
        path: BASE_PATH,
        canAccess: () => true
    },
    {
        component: Authorise,
        path: `${BASE_PATH}/authorise`,
        canAccess: () => true
    },
    {
        component: Welcome,
        path: `${BASE_PATH}/purchased`,
        canAccess: () => false,
        displayPreviousButton: false
    }
]

export const PurchaseQuoteInviteFinanceRoutes = () => {
    return  (
        <SubscriptionQuoteLoader>
            <PurchaseQuote>
                <PageRouter routes={routes} />
            </PurchaseQuote>
        </SubscriptionQuoteLoader>
    )
}