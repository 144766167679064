import { untrack } from '@punnet/web-client-kit-library'
import { startOfToday } from 'date-fns'
import { createMemo, Show } from 'solid-js'
import { GreyButton, SecondaryButton } from '../../../components/Button/Buttons'
import { ManageEmployeesModal } from '../../../components/ManageEmployees/modal/ManageEmployeesModal'
import { QuoteFooter } from '../../../components/Members/Footer/QuoteFooter'
import { MembersList } from '../../../components/Members/List/MembersList'
import { mapToDraftPolicies } from '../../store/members/mapToMemberPolicies'
import { useMembersAddedPolicies, useMembersPlans, useMembersStoreActions } from '../../store/members/membersStore'
import { MANAGE_EMPLOYEES_LIFE_VALIDATION, MANAGE_EMPLOYEES_TOOLTIPS, useManageStoreActions, useManageStoreShowModal } from '../../store/quotes/manageEmployeesStore'
import { PageTitle } from '../PageTitle'
import styles from './Members.module.css'

export function Members() {
    const { reset, isEditMode } = useMembersStoreActions()

    const isEdit = createMemo(isEditMode)

    return (
        <div class={styles.Members}>
            <PageTitle>Members</PageTitle>

            <div class={styles.Buttons}>
                <div class={styles.EditMemberContainer}>
                    <ManageEmployees />
                    <Show when={isEdit()}>
                        <SecondaryButton onClick={reset}>Exit edit</SecondaryButton>
                    </Show>
                </div>

                <div>
                    {/* EAP and search bar */}
                </div>
            </div>
            <MembersList />
            <Show when={isEdit()}>
                <QuoteFooter />
            </Show>
        </div>
    )
}

const ManageEmployees = () => {
    const showModal = () => useManageStoreShowModal()
    const { toggleModal } = useManageStoreActions()
    const { updateAddMembers } = useMembersStoreActions()

    const plans = () => untrack(memberPolicies())
    const memberPolicies = () => mapToDraftPolicies(untrack(useMembersAddedPolicies()))

    return (
        <>
            <GreyButton onClick={toggleModal}>Add employees</GreyButton>

            <ManageEmployeesModal
                isOpen={showModal()}
                onDismiss={toggleModal}
                plans={useMembersPlans()}
                policies={plans()}
                // TODO
                startDate={startOfToday().getTime()}
                minPolicyCount={1}
                onUpdate={updateAddMembers}
                lifeValidation={MANAGE_EMPLOYEES_LIFE_VALIDATION}
                toolTips={MANAGE_EMPLOYEES_TOOLTIPS}
            />
        </>
    )
}