import { currencyFromPence, displayableId, readableDate } from '@punnet/pure-utility-kit'
import type { QuoteSummary, QuoteSummaryList } from '@punnet/subscription-pure'
import { useNavigate } from '@solidjs/router'
import { For, type Component } from 'solid-js'
import { ListTable } from '../../ListTable/ListTable'


export type QuotesListProps = {
    quotes?: QuoteSummaryList
}

const quoteStatus = (quote: QuoteSummary) => quote.sharedWith?.length ? 'SHARED' : 'DRAFT'

const headers = [
    'Quote ID',
    'Last edited',
    'Company name',
    'Postcode',
    'Start date',
    'Total members',
    'Monthly premium',
    'Quote stage',
    'Assigned to'
]

export const QuotesList: Component<QuotesListProps> = (props) => {
    return (
        <ListTable headers={headers}>
            <For each={props.quotes}>
                {(quote) => <QuoteRow {...quote} />}
            </For>
        </ListTable>
    )
}

const QuoteRow: Component<QuoteSummary> = (quoteSummary) => {
    const navigate = useNavigate()
    const onClick = () => navigate(`/quotes/edit/${quoteSummary.quoteId}`)

    return (
        <tr onClick={onClick}>
            <td>{displayableId(quoteSummary.quoteId)}</td>
            <td>{readableDate(quoteSummary.quoteDate, 'dd MMM yy HH:mm')}</td>
            <td>{quoteSummary.policyHolder}</td>
            <td>{quoteSummary.postcode}</td>
            <td>{readableDate(quoteSummary.startDate)}</td>
            <td>{quoteSummary.members}</td>
            <td>{currencyFromPence(quoteSummary.premium)}</td>
            <td>{quoteStatus(quoteSummary)}</td>
            <td>{quoteSummary.ownedBy?.map(p => p.principalName).filter(Boolean).join(', ')}</td>
        </tr>
    )
}
