import { classList } from '@punnet/pure-utility-kit'
import { For, mergeProps, type ParentComponent } from 'solid-js'
import styles from './ListTable.module.css'


export type ListTableProps = {
    headers: string[]
    disableHover?: boolean
    tableClass?: string
}

export const ListTable: ParentComponent<ListTableProps> = (propsIn) => {
    const props = mergeProps({disableHover: false}, propsIn)

    return (
        <table class={classList(styles.ListTable, props.tableClass)} data-list-table data-display-hover={!props.disableHover}>
            <thead>
                <tr class={styles.Header}>
                    <For each={props.headers}>
                        {(header) => <th>{header}</th>}
                    </For>
                </tr>
            </thead>

            <tbody>
                {props.children}
            </tbody>
        </table>
    )
}
