import { Suspense, createResource } from 'solid-js'
import { PageTitle } from '../PageTitle'
import { Pulse } from '../../../components/Pulse/Pulse'
import { ClaimsList } from '../../../components/Claims/ClaimsList/ClaimsList'
import styles from './Claims.module.css'

export const Claims = () => {
    // load data - will be dummy data for now
    const [claims] = createResource(async () => {
        const { default: claims } = await import('./tempClaims.json')
        return claims
    })

    return (
        <div class={styles.Claims}>
            <div>
                <PageTitle>Claims Queue</PageTitle>
            </div>

            <div>
                <Suspense fallback={<Pulse isShown={true} />}>
                    <ClaimsList claims={claims()} />
                </Suspense>
            </div>
        </div>
    )
}
