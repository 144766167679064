import { PageRouter } from '../../../../../components/PageRouter/PageRouter'
import type { PageRouterPath } from '../../../../../components/PageRouter/PageRouterPath'
import { PlanDetails } from './PlanDetails'
import { Authorise } from './Authorise'
import { DirectDebitAuthority } from './DirectDebitAuthority'
import { DirectDebitDetails } from './DirectDebitDetails'
import { PurchaseQuote } from '../PurchaseQuote'
import { SubscriptionQuoteLoader } from '../SubscriptionQuoteLoader'
import { Schedules } from './Schedules'
import { Upgrades } from './Upgrades'
import { useStoreActions } from '../../../../store/quotes/quoteAndBuyStore'
import { Welcome } from '../Welcome'

const BASE_PATH = '/quotes/:quoteId'

const routes = [
    {
        component: PlanDetails,
        path: BASE_PATH,
        canAccess: () => true
    },
    {
        component: Upgrades,
        path: `${BASE_PATH}/upgrades`,
        canAccess: () => {
            // TODO: this should really check that all the data for the first page is valid (i.e. all lives are valid and we have a quote)
            // for now, returning false will prevent the user from accessing this page directly and boot them back to the first page
            return false
        }
    },
    {
        component: Schedules,
        path: `${BASE_PATH}/schedules`,
        canAccess: () => {
            const state = useStoreActions.getState()
            return state.allowUpgradePlanLevels !== undefined || state.allowAddAdultToPlan !== undefined
        }
    },
    {
        component: DirectDebitAuthority,
        path: `${BASE_PATH}/dd-authority`,
        canAccess: () => {
            const state = useStoreActions.getState()
            return state.sendWelcomeEmailSchedule !== undefined
        }
    },
    {
        component: DirectDebitDetails,
        path: `${BASE_PATH}/dd-details`,
        canAccess: () => {
            const state = useStoreActions.getState()
            return !!state.adminHasDirectDebitAuthority
        }
    },
    {
        component: Authorise,
        path: `${BASE_PATH}/authorise`,
        canAccess: () => {
            const state = useStoreActions.getState()
            return !!state.directDebitDetails
        }
    },
    {
        component: Welcome,
        path: `${BASE_PATH}/purchased`,
        canAccess: () => false,
        displayPreviousButton: false
    }
] as PageRouterPath[]


export function PurchaseQuoteRoutes() {
    return (
        <SubscriptionQuoteLoader>
            <PurchaseQuote>
                <PageRouter routes={routes}></PageRouter>
            </PurchaseQuote>
        </SubscriptionQuoteLoader>
    )
}