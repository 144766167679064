import { classList } from '@punnet/pure-utility-kit'
import { useSubscriptionService } from '@punnet/subscription-web-client'
import type { Component, ParentComponent } from 'solid-js'
import { Show, createSignal } from 'solid-js'
import { GreyButton, PrimaryButton } from '../../../../components/Button/Buttons'
import { SummaryOfChanges } from '../../../../components/Members/SummaryOfChanges/SummaryOfChanges'
import { previousPage } from '../../../../components/PageRouter/PageRouter'
import { Pulse } from '../../../../components/Pulse/Pulse'
import { FormattedPrice } from '../../../../components/Quote/Price/FormattedPrice'
import { Price } from '../../../../components/Quote/Price/Price'
import { getActiveAccount, getCurrentTotalMonthlyPremium } from '../../../services/AccountProvider'
import { getAlteredMemberPolicies } from '../../../store/members/mapToMemberPolicies'
import { mapToSubscriptionRequest } from '../../../store/members/mapToSubscriptionRequest'
import { useMembersPolicies } from '../../../store/members/membersStore'
import { getTotalMonthlyPremium } from '../../Quotes/QuoteController/QuoteController'
import styles from './MembersSummary.module.css'

export function MembersSummary() {
    const subscriptionService = useSubscriptionService()
    const activeAccount = getActiveAccount()

    const [isActivating, setIsActivating] = createSignal(false)
    const [error, setError] = createSignal('')

    const onActivateAlteration = async (e: Event) => {

        // TODO: ensure we are in a state where we can purchase (valid policies/lives, accepted terms & conditions etc)
            
        setIsActivating(true)
        setError('')

        try {
            const request = mapToSubscriptionRequest(activeAccount, useMembersPolicies())
            console.log('request', request)

            const response = await subscriptionService.alterSmeSubscription(request)
            console.log('Subscription altered', response)

            // TODO: redirect to a confirmation page
            document.location.href = '/members'

        } catch (error: any) {
            console.error(error)
            setError(error?.message ?? 'An unknown error occurred')
        } finally {
            setIsActivating(false)
        }
    }

    const currentPremium = () => getCurrentTotalMonthlyPremium()
    const newPremium = getTotalMonthlyPremium()
    const changedPolicies = getAlteredMemberPolicies(useMembersPolicies())

    return (
        <div class={styles.Summary}>
            <Section>
                <h1>Almost done!<br />
                    Please review your plan changes</h1>
                {/* TODO: crude implementation of showing errors for now - refactor! */}
                <Show when={error()}>
                    <span style={{color: 'red'}}>{error()}</span>
                </Show>
            </Section>

            <Section>
                <SummaryOfChanges changedPolicies={changedPolicies} />
            </Section>

            <Cost current={currentPremium()} new={newPremium} />

            <Section>
                <h1>Are you happy with these changes?</h1>
                <div class={styles.ButtonContainer}>
                    <GreyButton onClick={previousPage}>Back to edit</GreyButton>
                    <PrimaryButton onClick={onActivateAlteration}>Yes, update</PrimaryButton>
                </div>
            </Section>
            <Pulse isShown={isActivating()} />
        </div>
    )
}

const Cost: Component<{ current: number, new: number }> = (props) => {
    return <>
        <Section>
            <h4>Cost</h4>
            <p>You currently pay <b><Price value={props.current} /></b> per month. Following these plan changes and provided no more changes are made, you premium will now be:</p>
        </Section>
        <Section class={styles.Wide}>
            <div class={styles.Premiums}>
                <FormattedPrice price={props.new} />
            </div>
        </Section>
        <Section>
            <p>These changes will take effect immediately once you confirm below.</p>
            <p>Your <b>next payment</b> will be adjusted on a pro rata basis to account for changes made mid-month.</p>
            <p>All <b>new employees</b> being covered will receive an email with their policy certificate and policy terms and conditions, and instructions on how to download the Sovereign app.</p>
        </Section>
    </>
}

const Section: ParentComponent<{ class?: string }> = (props) => {
    return (
        <section class={styles.Section}>
            <div class={classList(styles.Contents, props.class)}>
                {props.children}
            </div>
        </section>
    )
}
