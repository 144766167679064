import { newUUID } from '@punnet/pure-utility-kit'
import { SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import type { QuoteAndBuyStateData } from './quoteAndBuyStore'

const updateQuoteRequest = (state: QuoteAndBuyStateData, draft?: SubscriptionQuoteResponse): SubscriptionQuoteResponse => {
    const quoteRequest = {
        ...draft,
        account: {
            ...draft?.account,
            id: draft?.account?.id ?? newUUID(),
            name: state.company?.name,
            type: 'COMPANY',
            company: state.company,
            contactEmail: state.customerEmailAddress ?? ''
        },
        subscription: {
            ...draft?.subscription,
            id: draft?.subscription?.id ?? state.subscriptionId ?? newUUID(),
            name: state.company?.name,
            timezone: 'Europe/London',
            startDate: state.startDate,
            contactEmail: state.customerEmailAddress ?? '',
            allowUpgradePlanLevels: state.allowUpgradePlanLevels,
            allowAddAdultToPlan: state.allowAddAdultToPlan
        },
        plans: state.plans,
        policies: state.policies.map(p => ({
            ...p,
            startDate: state.startDate,
        })),
        sendWelcomeEmailSchedule: state.sendWelcomeEmailSchedule,
        adminHasDirectDebitAuthority: state.adminHasDirectDebitAuthority,
        directDebitDetails: state.directDebitDetails
    } as SubscriptionQuoteResponse

    return quoteRequest
}

//TODO: rename
export function mapToSubscriptionRequest(state: QuoteAndBuyStateData, savedQuote?: SubscriptionQuoteResponse): SubscriptionQuoteResponse {
    const draft = updateQuoteRequest(state, savedQuote)
    draft.quoteRequestId = state.quoteRequestId
    return draft
}
