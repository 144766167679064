import { SubscriptionQuoteResponse } from '@punnet/subscription-pure'
import type { QuoteAndBuyStateData } from './quoteAndBuyStore'
import { toClass } from '@punnet/model-validation-pure'

export function mapFromSubscriptionRequest(quote: SubscriptionQuoteResponse): QuoteAndBuyStateData {
    const subscriptionId = toClass(quote, SubscriptionQuoteResponse).getQuoteId()
    

    return {
        plans: quote.plans,
        policies: quote.policies,
        company: quote.account.company,
        startDate: quote.subscription.startDate,
        customerEmailAddress: quote.account.contactEmail,
        quoteRequestId: quote.quoteRequestId,
        subscriptionId,
        allowUpgradePlanLevels: quote.subscription.allowUpgradePlanLevels,
        allowAddAdultToPlan: quote.subscription.allowAddAdultToPlan,
        sendWelcomeEmailSchedule: quote.sendWelcomeEmailSchedule,
        adminHasDirectDebitAuthority: quote.adminHasDirectDebitAuthority,
        directDebitDetails: quote.directDebitDetails
    }
}