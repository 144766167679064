import type { RestrictedRouteDefinition } from '@punnet/web-client-kit-library'
import { Navigate } from '@solidjs/router'
import { Home } from '../pages/Home'

export const routesAll: RestrictedRouteDefinition[] = [
    {
        path: '/',
        component: Home,
        canAccess: () => true
    },
    {
        path: '*',
        component: () => <Navigate href="/"/>,
        canAccess: () => true
    }
]