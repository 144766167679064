import { createContext, useContext, type ParentComponent } from 'solid-js'
import type { RestrictedRouteDefinition } from './RestrictedRouteDefinition'
import { RestrictedRoutes } from './RestrictedRoutes'

type Props = {
    routes: RestrictedRouteDefinition[]
}

const Context = createContext<RestrictedRoutes>()

export const RestrictedRouteProvider: ParentComponent<Props> = (props) => {
    const routes = new RestrictedRoutes(props.routes)

    return <Context.Provider value={routes}>
        {props.children}
    </Context.Provider>
}

function getContext() {
    return useContext(Context)
}

export const useRestrictedRoutes = () => {
    return getContext()?.getRoutes()
}

export const isRouteAccessible = (route: string) => {
    return getContext()?.isRouteAccessible(route)
}

export const getRouteNavigationPrimary = (route: string) => {
    return getContext()?.getRouteNavigationPrimary(route)
}

export const getRouteNavigationSecondary = (route: string) => {
    return getContext()?.getRouteNavigationSecondary(route)
}
