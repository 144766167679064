import { Optional, keys } from '@punnet/pure-utility-kit'
import { CognitoUserAttributes, JwtClaims, type UserProfile } from './iam-types'

type JwtContainingEvent = {requestContext?: {authorizer?: {jwt?: {claims?: any}}}}


export function claimsIn<ClaimsType extends JwtClaims<CognitoUserAttributes>>(event: JwtContainingEvent): Optional<ClaimsType> {
    return event.requestContext?.authorizer?.jwt?.claims as Optional<ClaimsType>
}



export function mapToCognitoUserAttributes<Profile extends Omit<UserProfile, 'principalId'>>(
    userProfile: Profile,
    existingUserProfile?: Profile
): Partial<CognitoUserAttributes> {
        
    const attributes: Partial<CognitoUserAttributes> = {}

    if (existingUserProfile?.firstname !== userProfile.firstname) {
        attributes.given_name = userProfile.firstname
    }
    if (existingUserProfile?.lastname !== userProfile.lastname) {
        attributes.family_name = userProfile.lastname
    }
    if (existingUserProfile?.email !== userProfile.email) {
        attributes.email = userProfile.email
    }
    if (existingUserProfile?.phone !== userProfile.phone) {
        attributes.phone_number = userProfile.phone
    }
    if (existingUserProfile?.contentBucketPath !== userProfile.contentBucketPath) {
        attributes['custom:content_bucket_path'] = userProfile.contentBucketPath
    }
    if (existingUserProfile?.intercomContactId !== userProfile.intercomContactId) {
        attributes['custom:intercom_id'] = userProfile.intercomContactId
    }
    if (existingUserProfile?.accountId !== userProfile.accountId) {
        attributes['custom:accountId'] = userProfile.accountId
    }
    if (existingUserProfile?.subscriptionId !== userProfile.subscriptionId) {
        attributes['custom:subscriptionId'] = userProfile.subscriptionId
    }
    if (existingUserProfile?.policyId !== userProfile.policyId) {
        attributes['custom:policyId'] = userProfile.policyId
    }
    if (existingUserProfile?.lifeId !== userProfile.lifeId) {
        attributes['custom:lifeId'] = userProfile.lifeId
    }

    return keys(attributes).length ? attributes : null
}


