export * from './src/event-kit'

        import * as me from '@punnet/core-event-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src1426322548/src/codestar-connections.eu-west-2.amazonaws.com/git-http/730335353981/eu-west-2/ec6127c2-3e3a-4fab-b1a9-b6df4376a30f/peachy-punnet/peachy-mono-repo/punnet/stacks/core-event/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@punnet/core-event-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    