import AcupunctureIcon from './src/icons/acupuncture.svg'
import BirthIcon from './src/icons/birth.svg'
import ChiropodyIcon from './src/icons/chiropody.svg'
import DentalIcon from './src/icons/dental.svg'
import DiagnosticsIcon from './src/icons/diagnostics.svg'
import EapIcon from './src/icons/eap.svg'
import GpIcon from './src/icons/gp247.svg'
import HealthScreeningIcon from './src/icons/health-screening.svg'
import InPatientIcon from './src/icons/in-patient.svg'
import OpticalIcon from './src/icons/optical.svg'
import PerksIcon from './src/icons/perks.svg'
import PhysiotherapyIcon from './src/icons/physiotherapy.svg'
import PrescriptionIcon from './src/icons/prescription.svg'
import TickIcon from './src/icons/tick.svg'
import WellbeingIcon from './src/icons/wellbeing.svg'
import DirectDebitLogo from './src/icons/direct-debit.svg'
import SovereignLogoSvg from './src/sovereign-logo.svg'

// benefit icons must be exported under a name that matches it's benefit id
const BENEFITS = {
    OPTICAL: OpticalIcon,
    DENTAL: DentalIcon,
    PRESCRIPTION: PrescriptionIcon,
    PHYSIO: PhysiotherapyIcon,
    CHIROPODY: ChiropodyIcon,
    ACUPUNCTURE: AcupunctureIcon,
    HEALTH_SCREENING: HealthScreeningIcon,
    CONSULTATIONS_DIAGNOSTICS: DiagnosticsIcon,
    HOSPITAL_IN_PATIENT: InPatientIcon,
    BIRTH_ADOPTION: BirthIcon,
    GP_SERVICE: GpIcon,
    SOVEREIGN_PERKS: PerksIcon,
    HEALTH_WELLBEING: WellbeingIcon,
    EAP: EapIcon,
}

export { 
    DirectDebitLogo,
    TickIcon,
    BENEFITS
}

export { SovereignLogoSvg }
        import * as me from '@sovereign/assets'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported) && typeof exported === 'function') {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src1426322548/src/codestar-connections.eu-west-2.amazonaws.com/git-http/730335353981/eu-west-2/ec6127c2-3e3a-4fab-b1a9-b6df4376a30f/peachy-punnet/peachy-mono-repo/sovereign/assets'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@sovereign/assets'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    