import {Type} from 'class-transformer'
import {Account} from './Account'
import {Subscription} from './Subscription'
import {Policy} from './Policy'
import {Plan} from './Plan'

import { AnEvent } from '@punnet/core-event-pure'


export class SubscriptionQuoteRequest extends AnEvent {

    lastUpdatedTimestamp?: number
    quoteRequestId: string
    quoteRequestTimestamp: number

    description?: string

    @Type(() => Account)
    public account: Account

    @Type(() => Subscription)
    public subscription: Subscription

    @Type(() => Plan)
    public plans: Plan[]

    @Type(() => Policy)
    public policies: Policy[]

    public intercomVisitorId?: string

    public ownership?: string[]

    // temporary fields for storing the data from sovereign
    public sendWelcomeEmailSchedule?: string
    public adminHasDirectDebitAuthority?: boolean
    public directDebitDetails?: {
        accountNumber: string
        sortCode: string
        accountName: string
        agreeTerms: boolean
        collectionDate: number // from 1-31
    }

    constructor(from: SubscriptionQuoteRequest) {
        super()
        Object.assign(this, from)
    }

    getQuoteId?(): string {
        return this.subscription.id
    }
}
