import { PaymentDetails } from './PaymentDetails'
import { Policy } from './Policy'

import { Required } from '@punnet/model-validation-pure'
import { Plan } from './Plan'

import {
    displayableId,
    getNextAnniversaryOf,
    getNextPaymentDateAfter,
    KeyMapped,
    values,
} from '@punnet/pure-utility-kit'
import { Type } from 'class-transformer'
import { startOfToday } from 'date-fns'
import { Life } from './Life'
import { HasLifecycleStatus, isCancelled, LifecycleStatus, TransferDetails } from './LifecycleStatus'
import type { Pence } from '@peachy/core-domain-pure'

export const defaultTimezone = 'Europe/London'


export class PaymentProviderSubscription {
    providerName: string
    customerId: string
    subscriptionId: string
    productId: string
}


export class Subscription implements HasLifecycleStatus {

    @Required()
    id: string

    versionIdx?: number
    versionDate?: number

    @Required()
    startDate: number

    status: LifecycleStatus
    endDate?: number
    cancellationReason?:string
    nextRenewalDate?: number
    lastRenewalDate?: number

    transfer?: TransferDetails

    timezone: string = defaultTimezone

    billingAnchor?: number // integer day of month

    totalMonthlyPremium?: Pence

    paymentProvider?: PaymentProviderSubscription
    
    plans: KeyMapped<Plan> = {}

    policies: KeyMapped<Policy> = {}

    name: string
    contactName: string
    contactEmail: string

    @Type(() => PaymentDetails)
    paymentDetails?: PaymentDetails

    distributorId?: string

    allowUpgradePlanLevels?: boolean
    allowAddAdultToPlan?: boolean


    referenceNumber?(): string {
        return displayableId(this.id)
    }

    getNextPaymentDate?(): number {
        return getNextPaymentDateAfter(new Date(this.startDate), startOfToday()).getTime()
    }

    getAnnualRenewalDate?(): Date {
        return getNextAnniversaryOf(new Date(this.startDate))
    }


    getActivePolicies?(): Policy[] {
        return values(this.policies).filter(p => !isCancelled(p))
    }


    findPolicy? = (where: (policy: Policy) => boolean) =>
        values(this.policies).find(p => where(p))

    hasPolicy? = (policyId: string) =>
        !!this.policies[policyId]


    resolveLife? = (lifeId: string) =>
        this.findPolicy(p => lifeId in p.lives)?.lives[lifeId]

    resolvePlan? = (planId: string) =>
        this.plans[planId] ?? this.findPolicy(p => planId in p.plans)?.plans[planId]

    findLife? = (where: (life: Life) => boolean) =>
        values(this.policies).flatMap(p => values(p.lives)).find(l => where(l))

    findLifeAndPolicy?(where: (life: Life, policy: Policy) => boolean): [Life, Policy] {
        for(const policy of values(this.policies)) {
            for(const life of values(policy.lives)) {
                if (where(life, policy)) return [life, policy]
            }
        }
        return [null, null]
    }

    resolveLifeAndPolicyByEmail? = (email: string): [Life, Policy] =>
        this.findLifeAndPolicy((l) => l.email === email )
    resolveLifeAndPolicy? = (lifeId: string): [Life, Policy] =>
        this.findLifeAndPolicy((l) => l.id === lifeId )
}
