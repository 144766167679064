import { Spinner } from '@punnet/web-client-kit-library'
import { useLocation, useNavigate } from '@solidjs/router'
import { Show, createMemo, type Component } from 'solid-js'
import { QuoteFooterButton } from '../../../../components/Button/Buttons'
import { nextPage } from '../../../../components/PageRouter/PageRouter'
import { FormattedPrice } from '../../../../components/Quote/Price/FormattedPrice'
import { Footer } from '../../../layouts/main-layout/Footer/Footer'
import { getPermissions } from '../../../services/permissions/PermissionsController'
import { useManageStoreActions } from '../../../store/quotes/manageEmployeesStore'
import { useStoreActions } from '../../../store/quotes/quoteAndBuyStore'
import { getPrice } from '../../../services/quote/getPrice'
import { useIsQuoting } from '../QuoteController/QuoteController'
import styles from './QuoteFooter.module.css'

export const QuoteFooter: Component = () => {
    const permissions = getPermissions()

    const canQuote = useStoreActions.canQuote
    const isQuoting = useIsQuoting()

    const price = createMemo (() => getPrice())
    const hasPrice = () => price() && !isQuoting()

    return (
        <Footer class={styles.Footer}>
            <div>
                <Show when={canQuote()}>
                    <Show when={hasPrice()} fallback={<Spinner />}>
                        <FormattedPrice price={price()} />
                    </Show>
                </Show>
            </div>
            <div>
                <Show when={permissions?.canShareQuote()}>
                    <ShareButton />
                </Show>
                <Show when={permissions?.canPurchaseQuote()}>
                    <NextButton />
                </Show>
            </div>
        </Footer>
    )
}

const ShareButton: Component = () => {
    const navigate = useNavigate(), location = useLocation()

    const canShare = () => useStoreActions.canShare()
    const onShare = () => {
        if (!canShare()) return
        navigate(`${location.pathname}/contact`)
    }

    return (
        <QuoteFooterButton disabled={!canShare()} onClick={onShare}>Share &rarr;</QuoteFooterButton>
    )
}



// next button is for the Purchase Quote flow
const NextButton: Component = () => {
    const { enablePurchaseButton, validatePolicies } = useStoreActions
    const { toggleModal } = useManageStoreActions()

    const isEnabled = () => enablePurchaseButton()
    const onNext = () => validatePolicies() ? nextPage() : toggleModal()

    return (
        <QuoteFooterButton disabled={!isEnabled()} onClick={onNext}>Next &rarr;</QuoteFooterButton>
    )
}
