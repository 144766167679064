import { useSubscriptionService } from '@punnet/subscription-web-client'
import { Show, createSignal } from 'solid-js'
import { getQuoteSubscriptionRequest } from '../../../../services/quote/getQuoteSubscriptionRequest'
import { hasDirectDebitDetails } from '../../../../store/quotes/quoteAndBuyStore'
import { Title } from '../../Quote'
import { FormSection, TextSection } from '../../Section'
import { CompanySchemeHeader } from '../CompanySchemeHeader'
import { NavigationButtons } from '../NavigationButtons'


// TODO: a lot of this is the same as the stp version - refactor!
export const Authorise = () => {
    const [agree, setAgree] = createSignal(false)

    const isValid = () => {
        return agree() && hasDirectDebitDetails()
    }

    const subscriptionService = useSubscriptionService()

    const [error, setError] = createSignal('')

    const onSubmit = async () => {
        // TODO: handle the case where the subscription start date has expired
        // TODO: ensure we are in a state where we can purchase (valid policies/lives, accepted terms & conditions etc)
        
        setError('')

        const request = getQuoteSubscriptionRequest()
        console.log('activation request', request)

        try {
            // make a call to the server to activate the subscription!
            const response = await subscriptionService.activateSmeSubscription(request)
            // const response = await new Promise((resolve, reject) => setTimeout(resolve, 1000)) //TESTING :)
            console.log('activated!', response)
        } catch (error: any) {
            console.error(error)
            setError(error?.message ?? 'An unknown error occurred')
        }
    }

    return <>
        <TextSection>
            <CompanySchemeHeader />
            <p>Once your finance contact adds the Direct Debit details you can come back here and authorise the purchase</p>
            <p>Don't worry - we'll send you an email!</p>
        </TextSection>

        <FormSection>
            <Title>Authorise:</Title>
            {/* TODO: crude implementation of showing errors for now - refactor! */}
            <Show when={error()}>
                <span style={{color: 'red'}}>{error()}</span>
            </Show>
            <p>
                By clicking 'Authorise' you are authorising the purchase of this company scheme. 
                If you have included finance contact details, this scheme will be finalised once 
                they have added the Direct Debit details. Please make sure you read and accept the terms & conditions
            </p>
            <label>
                <input required type="checkbox" name="agree" checked={agree()} onChange={(e) => setAgree(e.target.checked)} />
                I agree to the Sovereign company scheme terms & conditions
            </label>
        </FormSection>

        <TextSection>
            <NavigationButtons nextButtonDisabled={!isValid()} nextButtonText="Authorise & finish" onNext={onSubmit} />
        </TextSection>
    </>
}