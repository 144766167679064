import type { Draft } from "@punnet/pure-utility-kit"
import type { Life, Plan, Policy } from "@punnet/subscription-pure"
import { untrack } from "@punnet/web-client-kit-library"
import { createWithStore } from "solid-zustand"
import { usePlanConfig } from "../../hooks/usePlanConfig"
import { createPlans } from "../createPlans"
import { mapToDraftPolicies, mapToMemberPolicies, type MemberPolicy, type MemberPolicyAction } from "./mapToMemberPolicies"
import { AGE_BOUNDS, validateLives } from "../../services/validation/validateLives"
import { findDuplicateEmails } from "../quotes/findDuplicateEmails"


const planConfig = usePlanConfig()
// TODO remove usage of config
export const MEMBERS_PLANS_FROM_CONFIG = createPlans(planConfig)

export type MembersStateData = {
    plans: Plan[]
    memberPolicies: MemberPolicy[]
    initial: MemberPolicy[] // Probably should be looking at the quote subscription
}


export type MembersState = MembersStateData & {
    actions: {
        updateAddMembers: (policies: Draft<Policy>[]) => void
        updateMemberAction: (id: string, action: MemberPolicyAction, revertToInitial?: boolean) => void
        updateMemberCover: (id: string, planId: string, action: MemberPolicyAction) => void
        removeMember: (id: string) => void
        isEditMode: () => boolean
        validateAddedPolicies: () => boolean
        reset: () => void
        init: (state: MembersStateData) => void
    }
}

const useMembersStore = createWithStore<MembersState>((set, get) => ({
    plans: [],
    memberPolicies: [],
    initial: [],
    actions: {
        updateAddMembers: (policies) => {
            set(state => ({ memberPolicies: [...mapToMemberPolicies(policies, 'ADD'), ...state.memberPolicies.filter(p => p.action !== 'ADD')] }))
        },
        updateMemberAction: (id: string, action: MemberPolicyAction, revertToInitial: boolean) => {
            let initialMemberPolicy: MemberPolicy
            if (revertToInitial) {
                initialMemberPolicy = untrack(get().initial.find(p => p.id === id))
            }

            set(state => ({ memberPolicies: state.memberPolicies.map(p => (p.id === id) ? { ...(initialMemberPolicy ?? p), action } : p) }))
        },
        // TODO store unit test 
        updateMemberCover: (id: string, planId: string, currentAction: MemberPolicyAction) => {
            let newAction: MemberPolicyAction
            let initialPrimary: Draft<Life>
            let initialSecondary: Draft<Life>

            // CHANGE_COVER - Revert cover back to original state if intial cover is set
            // IDLE - same cover should have no cover change
            if (['CHANGE_COVER', 'IDLE'].includes(currentAction)) {
                const initialMemberPolicy = untrack(get().initial.find(p => p.id === id))
                if (initialMemberPolicy.primary.planId === planId) {
                    initialPrimary = untrack(initialMemberPolicy.primary)
                    initialSecondary = initialMemberPolicy.secondary ? untrack(initialMemberPolicy.secondary) : undefined
                    newAction = 'IDLE'
                } else {
                    newAction = 'CHANGE_COVER'
                }
            }

            set(state => ({
                memberPolicies: state.memberPolicies.map(p => (p.id === id) ? {
                    ...p,
                    action: newAction ?? p.action,
                    primary: initialPrimary ?? { ...p.primary, planId },
                    secondary: initialSecondary ?? p.secondary ? { ...p.secondary, planId } : undefined
                } as MemberPolicy : p)
            }))
        },
        removeMember: (id: string) => set(state => ({ memberPolicies: state.memberPolicies.filter(p => p.id !== id) })),
        isEditMode: (): boolean => changedMemberPolicies().length > 0,
        validateAddedPolicies: () => validateAddedPolicies(),
        reset: () => set(state => ({ memberPolicies: untrack(state.initial) })),
        init: (state) => set({ ...state })
    }
}))

export const useMembersPlans = () => useMembersStore(state => state.plans)
export const useMembersPolicies = () => useMembersStore(state => state.memberPolicies)
export const useMembersAddedPolicies = (): MemberPolicy[] => useMembersPolicies().filter(p => p.action === 'ADD')
export const changedMemberPolicies = () => useMembersPolicies().filter((p: MemberPolicy) => ['ADD', 'CHANGE_COVER', 'REMOVE'].includes(p.action))

export const useMembersStoreActions = () => useMembersStore(state => state.actions)

// note these are not exported but they are used in the members store actions (need to be declared here in order for them to be reactive)

const validateAddedPolicies = (): boolean => {
    const policies = mapToDraftPolicies(useMembersAddedPolicies())
    return policies.every(p => validateLives(p.startDate, AGE_BOUNDS, p)) && !findDuplicateEmails(policies).length
}


export const subscribeToMembersStore = useMembersStore.subscribe