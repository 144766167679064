//todo-should move these and other generic config level types out of @peachy/repo-domain and into the product/plan config modules
import { BenefitConfigUtilisationPolicy, ClaimStages, Obligations, ProductsConfig, BenefitConfig } from '@peachy/repo-domain'
import rawPlanConfig from './sovereign-plan-config.json'
import type * as PlanConfigJson from '@punnet/product-pure'

const SovereignPlanConfig = rawPlanConfig as PlanConfigJson.Config

//todo-dp is this the correct lodgement window?
const mandatoryClaim: Pick<BenefitConfigUtilisationPolicy, 'CLAIM'> = {[ClaimStages.CLAIM]: {obligation: Obligations.MANDATORY, lodgementWindowInWeeks: 16}}

function globalDefinitionParts(type: string): Pick<BenefitConfig, 'type' | 'displayName'> {
    const definition = SovereignPlanConfig.benefits.find(it => it.id === type)
    return {
        type: definition.id,
        displayName: definition.name
    }
}

//todo-dp is all the app / claim config correct?
export const SovereignProductConfig: ProductsConfig = {
    planConfig: SovereignPlanConfig,
    benefitsByType: {
        OPTICAL: {
            ...globalDefinitionParts('OPTICAL'),
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        DENTAL: {
            ...globalDefinitionParts('DENTAL'),
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        PRESCRIPTION: {
            ...globalDefinitionParts('PRESCRIPTION'),
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        PHYSIO: {
            ...globalDefinitionParts('PHYSIO'),
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        CHIROPODY: {
            ...globalDefinitionParts('CHIROPODY'),
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        ACUPUNCTURE: {
            ...globalDefinitionParts('ACUPUNCTURE'),
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        HEALTH_SCREENING: {
            ...globalDefinitionParts('HEALTH_SCREENING'),
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        CONSULTATIONS_DIAGNOSTICS: {
            ...globalDefinitionParts('CONSULTATIONS_DIAGNOSTICS'),
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        HOSPITAL_IN_PATIENT: {
            ...globalDefinitionParts('HOSPITAL_IN_PATIENT'),
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        BIRTH_ADOPTION: {
            ...globalDefinitionParts('BIRTH_ADOPTION'),
            offerAsUpsell: true,
            utilisationPolicy: {
                limit: {unit: 'PENCE'},
                ...mandatoryClaim
            }
        },
        GP_SERVICE: {
            ...globalDefinitionParts('GP_SERVICE'),
            offerAsUpsell: false,
            drivesFeatures: ['BOOK_VGP']
        },
        SOVEREIGN_PERKS: {
            ...globalDefinitionParts('SOVEREIGN_PERKS'),
            offerAsUpsell: false,
            drivesFeatures: ['PERKS']
        },
        HEALTH_WELLBEING: {
            ...globalDefinitionParts('HEALTH_WELLBEING'),
            offerAsUpsell: false
        },
        EAP: {
            ...globalDefinitionParts('EAP'),
            offerAsUpsell: false,
            drivesFeatures: ['ADVICE_AND_COUNSELLING']
        },
    },
    parentBenefitsByType: {

    }
}