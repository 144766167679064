import { PrincipalTypes } from '@punnet/subscription-pure'

export class Permissions {
    private isBroker: boolean

    constructor (private readonly token: any) {

        console.log('idToken', token)

        this.isBroker = this.#isBroker()
    }

    canShareQuote() {
        return this.isBroker
    }

    canPurchaseQuote() {
        return !this.isBroker
    }

    canEditEmployeeDetails() {
        return !this.isBroker
    }

    canEditEmployeeCounts() {
        return this.isBroker
    }

    canViewQuotes() {
        return this.isBroker
    }

    isFinanceUser() {
        return this.token['userProfile:principalType'] === PrincipalTypes['FINANCE-USER']
    }

    isBackOfficeUser() {
        const isBackOfficeUser = this.token?.email?.includes('+bo')
        return isBackOfficeUser ?? false
    }

    #isBroker(): boolean {
        const principalType = this.token['userProfile:principalType']
        const emailIsBroker = [PrincipalTypes['BROKER'],PrincipalTypes['BROKER-ADMIN']].includes(principalType)
        return emailIsBroker ?? false
    }
}
