import { SubscriptionQuoteLoader } from '../SubscriptionQuoteLoader'
import { DirectDebitDetails } from '../stp/DirectDebitDetails'
import type { Component } from 'solid-js'
import { PurchaseQuote } from '../PurchaseQuote'
import styles from '../../Section.module.css'

export const DirectDebit: Component = () => {
    return <>
        <SubscriptionQuoteLoader>
            <PurchaseQuote>
                <div class={styles.StripeySections}>
                    <DirectDebitDetails/>
                </div>
            </PurchaseQuote>
        </SubscriptionQuoteLoader>
    </>
}