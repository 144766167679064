import type { Plan } from '@punnet/subscription-pure'
import { type Benefit, PlanConfig } from '@punnet/product-client'
import { classList } from '@punnet/pure-utility-kit'
import type { DisplayPlanConfig } from '@punnet/web-client-kit-pure'
import { For, JSXElement, Show, createMemo, createSignal, type Component, type ParentComponent } from 'solid-js'
import { Card } from '../Card'
import styles from './PlanConfiguration.module.css'

type PlanConfigurationProps = {
    planConfig: PlanConfig
    displayConfig: DisplayPlanConfig
    plans: Plan[]
    isPlanActive?: (planId: string) => boolean
    class?: string
    planActiveClass?: string
}

const [propsStore, setPropsStore] = createSignal<PlanConfigurationProps>()

// components

const Title: ParentComponent = (props) => {
    return <h4 class={styles.title}>{props.children}</h4>
}

const BenefitOption: Component<{ benefit: Benefit }> = (props): JSXElement => {
    const element: JSXElement = propsStore().displayConfig.benefitLabel(props.benefit)
    return <>
        {element}
    </>
}

type GroupedBenefits = {
    label?: string
    benefits: Benefit[]
}[]

const GroupLabel: ParentComponent = (props) => {
    return <div class={styles.groupLabel}>{props.children}</div>
}

const getGroupedBenefits = (planId: string): GroupedBenefits => {
    const benefitOptions = propsStore().planConfig.getBenefitsForPlan(planId)

    return propsStore().displayConfig.benefitGroups?.map(group => ({
        label: group.label,
        benefits: benefitOptions.filter(benefit => group.benefits.includes(benefit.id))
    })) || [{ benefits: benefitOptions }]
}

const BenefitOptions: Component = () => {
    const groupedBenefits = createMemo(() => getGroupedBenefits(propsStore().plans[0].configId))
    const hasGroupedBenefits = createMemo(() => groupedBenefits().some(group => group.label))
    
    return (
        <Card class={classList(styles.card, styles.benefitOptions)}>
            <Title>Benefit</Title>
            
            <For each={groupedBenefits()}>
                {(group) => (
                    <>
                        <For each={group.benefits}>
                            {(benefit, index) => (
                                <>
                                    <Show when={(!hasGroupedBenefits() && benefit.global) || (hasGroupedBenefits() && index() === 0)}>
                                        <GroupLabel>{group.label}</GroupLabel>
                                    </Show>
                                    <BenefitOption benefit={benefit} />
                                </>
                            )}
                        </For>
                    </>
                )}
            </For>
        </Card>
    )
}

const PlanCard: ParentComponent<{ planId: string }> = (props) => {
    const isPlanSelected = createMemo(() => propsStore().isPlanActive?.(props.planId) ?? false)

    return (
        <Card class={classList(styles.card, isPlanSelected() ? propsStore().planActiveClass : '')}>
            {props.children}
        </Card>
    )
}

const BenefitValue: Component<{ benefit: Benefit, plan: Plan }> = (props) => {
    const benefitDefn = propsStore().displayConfig.benefits[props.benefit.id]
    return (
        <div>
            {benefitDefn.display(props.benefit, props.plan, propsStore().displayConfig.readonly)}
        </div>
    )
}

// This renders the vertical plan cards and their benefits (EXCLUDES GLOBAL BENEFITS)
const AvailablePlan: Component<{ plan: Plan }> = (props) => {
    const groupedBenefits = createMemo(() => getGroupedBenefits(props.plan.configId))
    const groupedPlanBenefits = createMemo(() => groupedBenefits().filter((group) => group.benefits.some((benefit) => !benefit.global)))
    
    return (
        <PlanCard planId={props.plan.id}>
            <Title>{props.plan.name}</Title>
            <For each={groupedPlanBenefits()}>
                {(group) => (
                    <>
                        {/* if we have group labels rendered on the benefit label, then we need to also display in the plan card */}
                        <Show when={group.label}>
                            <GroupLabel>{/* empty on purpose */}</GroupLabel>
                        </Show>
                        <For each={group.benefits}>
                            {(benefit) => 
                                <Show when={!benefit.global}>
                                    <BenefitValue benefit={benefit} plan={props.plan} />
                                </Show>
                             }
                        </For>
                    </>
                )}
            </For>
        </PlanCard>
    )
}

const GlobalBenefit: Component<{ benefit: Benefit }> = (props) => {
    return (
        <Card class={styles.card}>
            <BenefitValue benefit={props.benefit} plan={propsStore().plans[0]} />
        </Card>
    )
}

const Plans: Component = () => {
    const globalBenefits = createMemo(() => 
        propsStore().planConfig
            .getBenefitsForPlan(propsStore().plans[0].configId)
            .filter((benefit) => benefit.global)
    )

    return (
        <div class={styles.benefitsContainer}>
            <div class={styles.planCardsContainer}>
                <For each={propsStore().plans}>
                    {(plan) => <AvailablePlan plan={plan} />}
                </For>
            </div>
            <Show when={globalBenefits().length > 0}>
                <div class={styles.globalBenefitsContainer}>
                    <For each={globalBenefits()}>
                        {(benefit) => <GlobalBenefit benefit={benefit} />}
                    </For>
                </div>
            </Show>
        </div>
    )
}

export const PlanConfiguration: Component<PlanConfigurationProps> = (props) => {
    // if using with a zustand store we cannot use a new object otherwise we lose the signal
    setPropsStore(props)

    return (
        <div class={classList(styles.cardContainer, props.class)}>
            <BenefitOptions/>
            <Plans/>
        </div>
    )
}
