[
    {
        "id": "180d90db-c7e7-421f-8da4-e16752d7bdb6",
        "reference": "SVN 1234", 
        "stage": "CLAIM",
        "dateSubmitted": 1732645683478,
        "treatmentReceiverId": "f2353ec8-c16a-4470-b3b6-e5cfdb24fe88",
        "treatmentDate": 1732579200000,
        "benefitType": "Acupuncture",
        "member": {
            "id": "f2353ec8-c16a-4470-b3b6-e5cfdb24fe88",
            "firstname": "James",
            "lastname": "Bond"
        },
        "company": {
            "id": "f2353ec8-c16a-4470-b3b6-e5cfdb24fe88",
            "name": "Small Co."
        },
        "status": "OPEN",
        "policyNumber": "L0PH56G"
    },
    {
        "id": "862225a7-fcde-4adf-9b31-054d831065ba",
        "reference": "SVN SS90", 
        "stage": "CLAIM",
        "dateSubmitted": 1732645683478,
        "treatmentReceiverId": "4ee05a6f-5fa3-47e5-8357-56186b486851",
        "treatmentDate": 1732579200000,
        "benefitType": "Chiropody",
        "member": {
            "id": "4ee05a6f-5fa3-47e5-8357-56186b486851",
            "firstname": "Steve",
            "lastname": "Jobs"
        },
        "company": {
            "id": "febb3376-be6b-448d-b6e3-f4859381fa8c",
            "name": "The Tech Guys"
        },
        "status": "OPEN",
        "policyNumber": "J0M678K"
    }
]